import React from 'react';
import PropTypes from 'prop-types';
import colors from 'customizations/js/color-variables';

export const IconStarActive = ({ className, onClick, color }) => (
  <svg
    className={className}
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0864 5.26147C13.3703 5.26147 13.6286 5.4256 13.7493 5.68263L15.6694 9.77293L19.9483 10.4306C20.2195 10.4723 20.4447 10.6621 20.5316 10.9224C20.6186 11.1827 20.5527 11.4697 20.361 11.6661L17.244 14.8585L17.9811 19.3773C18.0262 19.6541 17.9093 19.9324 17.68 20.0939C17.4507 20.2554 17.1493 20.2718 16.9039 20.1361L13.0864 18.025L9.26885 20.1361C9.0234 20.2718 8.72199 20.2554 8.49269 20.0939C8.26339 19.9324 8.14648 19.6541 8.19163 19.3773L8.92868 14.8585L5.81167 11.6661C5.61996 11.4697 5.5541 11.1827 5.64106 10.9224C5.72801 10.6621 5.95319 10.4723 6.22443 10.4306L10.5033 9.77293L12.4234 5.68263C12.5441 5.4256 12.8024 5.26147 13.0864 5.26147Z"
      fill={color}
    />
  </svg>
);

IconStarActive.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.string,
  color: PropTypes.string,
};

IconStarActive.defaultProps = {
  className: '',
  onClick: null,
  color: colors.white
};
