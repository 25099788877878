import React from 'react';
import PropTypes from 'prop-types';
import colors from 'customizations/js/color-variables';

export const IconStarOutline = ({
  className,
  color,
  onClick,
}) => (
  <svg
    className={className}
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
  >
    <path
      d="M20.6528 11.798C20.9677 11.4977 21.0789 11.0569 20.943 10.6471C20.8069 10.2374 20.4522 9.94488 20.0165 9.8828L16.1424 9.33198C15.9774 9.30847 15.8348 9.20721 15.7611 9.0608L14.0291 5.62575C13.8346 5.23983 13.4399 5 13 5C12.5604 5 12.1657 5.23983 11.9712 5.62575L10.2389 9.0608C10.1652 9.20721 10.0223 9.30847 9.85732 9.33198L5.9832 9.88312C5.5478 9.94488 5.19313 10.2374 5.05696 10.6471C4.92112 11.0569 5.03229 11.4977 5.34723 11.798L8.1503 14.4715C8.2698 14.5857 8.32459 14.7499 8.2964 14.9104L7.6348 18.686C7.56047 19.1101 7.73508 19.5305 8.09103 19.7838C8.44666 20.0375 8.90962 20.0704 9.29953 19.8694L12.7642 18.0869C12.9119 18.011 13.0881 18.011 13.2358 18.0869L16.7008 19.8694C16.87 19.9566 17.0532 19.9995 17.2355 19.9995C17.4723 19.9995 17.7081 19.9271 17.9093 19.7838C18.2652 19.5305 18.4399 19.1101 18.3655 18.686L17.7036 14.9108C17.6754 14.7499 17.7302 14.586 17.8497 14.4719L20.6528 11.798ZM17.0724 15.0167L17.734 18.7919C17.7674 18.9819 17.6921 19.1634 17.5325 19.2769C17.3726 19.3901 17.1737 19.4039 16.9991 19.3148L13.5341 17.532C13.3672 17.4464 13.1833 17.4031 13 17.4031C12.8167 17.4031 12.6332 17.4464 12.4659 17.5323L9.00157 19.3148C8.82632 19.4039 8.62736 19.3901 8.4678 19.2769C8.30825 19.1634 8.23328 18.9822 8.26628 18.7919L8.92788 15.0167C8.99164 14.6524 8.86829 14.2806 8.59788 14.0229L5.79449 11.3491C5.6532 11.2143 5.60514 11.0243 5.66634 10.8409C5.72721 10.6572 5.88004 10.5311 6.07515 10.5032L9.94895 9.9524C10.3228 9.89942 10.6461 9.66994 10.813 9.33825L12.5454 5.9032C12.6325 5.73015 12.8026 5.62701 12.9997 5.62701C13.197 5.62701 13.3668 5.73015 13.4543 5.9032L15.1866 9.33825C15.3536 9.66994 15.6765 9.89942 16.0504 9.9524L19.9245 10.5032C20.1196 10.5311 20.2725 10.6572 20.3333 10.8409C20.3942 11.0243 20.3465 11.2143 20.2052 11.3491L17.4021 14.0226C17.1317 14.2806 17.0084 14.6521 17.0724 15.0167Z"
      fill={color}
      stroke={color}
    />
  </svg>
);

IconStarOutline.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.string,
};

IconStarOutline.defaultProps = {
  className: '',
  color: colors.white,
  onClick: null,
};
