import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// import Button from 'reactstrap/lib/Button';
import colors from 'customizations/js/color-variables';
import {
  withLocale,
  withModalActions,
  withUser,
  withUserAgent,
} from 'core/hocs';
import { USER_FIELDS } from 'core/constants';
// import { FormattedTag } from 'core/components';
import { getStaticUrl } from 'core/helpers';

import { CardLabel } from 'components/card-label/card-label';
import { ImageLazyLoad, LAZY_LOAD_TYPES } from 'components/image-lazy-load/image-lazy-load';
import { IconStarOutline } from 'components/icons/icon-star-outline/icon-star-outline';
import { IconStarActive } from 'components/icons/icon-star-active/icon-star-active';
import { IconPlayCircle } from 'components/icons/icon-play-circle/icon-play-circle';
import { formatAmountWithCurrency } from '../../helpers/numbers';
import { GameCardStub } from './game-card-stub';
import { MOBILE } from '../../constants';

import './game-card.scss';

const DEFAULT_THUMB_PATH = `${getStaticUrl()}/images/default-game-bg/thumb.jpg`;

export const GAME_CARD_SIZES = {
  SMALL: 'sm',
  MEDIUM: 'md',
};

export class GameCardUI extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    isUserLoggedIn: PropTypes.bool.isRequired,
    title: PropTypes.string,
    gameId: PropTypes.string,
    provider: PropTypes.string,
    producer: PropTypes.string,
    thumbPath: PropTypes.string,
    toggleFavoriteGame: PropTypes.func.isRequired,
    openGame: PropTypes.func.isRequired,
    isFavorite: PropTypes.bool,
    hasFun: PropTypes.bool,
    withFooter: PropTypes.bool,
    onOpenGame: PropTypes.func,
    amount: PropTypes.number,
    currency: PropTypes.string,
    size: PropTypes.string,
    withJackpotAmount: PropTypes.bool,
    isUserRegistered: PropTypes.bool.isRequired,
    deviceType: PropTypes.string.isRequired,
    isGamePage: PropTypes.bool,
    setScreenMode: PropTypes.func.isRequired,
  };

  static defaultProps = {
    withFooter: false,
    onOpenGame: null,
    isFavorite: false,
    amount: null,
    currency: null,
    size: GAME_CARD_SIZES.MEDIUM,
    withJackpotAmount: false,
    title: '',
    gameId: '',
    provider: '',
    producer: '',
    thumbPath: '',
    hasFun: false,
    isGamePage: false,
  };

  state = {
    isCardOverlayShown: false,
  };

  toggleFavorite = (e) => {
    e.stopPropagation();

    const {
      toggleFavoriteGame,
      gameId,
      provider,
    } = this.props;

    toggleFavoriteGame({
      gameId,
      platformCode: provider,
    });
  };

  openGame = (e) => {
    e.stopPropagation();
    const {
      gameId,
      producer,
      openGame,
      onOpenGame,
      hasFun,
      isUserRegistered,
      isUserLoggedIn,
      deviceType,
      isGamePage,
      setScreenMode,
    } = this.props;

    const { isCardOverlayShown } = this.state;
    const isMobile = deviceType === MOBILE;

    if (isMobile && !isCardOverlayShown) {
      this.onOverlayShow();

      return;
    }

    openGame({
      gameId,
      producer,
      isFun: isUserLoggedIn
        ? false
        : hasFun && !isUserRegistered && !isUserLoggedIn,
    });

    if (onOpenGame) {
      onOpenGame({ gameId, producer });
    }

    if (isGamePage) {
      setScreenMode();
    }
  };

  // openDemoGame = (e) => {
  //   e.stopPropagation();
  //   const {
  //     gameId,
  //     producer,
  //     openGame,
  //     onOpenGame,
  //     isGamePage,
  //     setScreenMode,
  //   } = this.props;
  //
  //   openGame({ gameId, producer, isFun: true });
  //
  //   if (onOpenGame) {
  //     onOpenGame({ gameId, producer });
  //   }
  //
  //   if (isGamePage) {
  //     setScreenMode();
  //   }
  // };

  onOverlayShow = () => {
    this.setState({ isCardOverlayShown: true });
  };

  onOverlayBlur = () => {
    const isMobile = this.props.deviceType === MOBILE;

    if (!isMobile) {
      return;
    }

    this.setState({ isCardOverlayShown: false });
  };

  render() {
    const {
      gameId,
      locale,
      isUserLoggedIn,
      title,
      producer,
      thumbPath,
      isFavorite,
      amount,
      currency,
      // hasFun,
      withFooter,
      size,
      withJackpotAmount,
      deviceType,
    } = this.props;

    const { isCardOverlayShown } = this.state;

    const isCardSmall = size === GAME_CARD_SIZES.SMALL;
    const isMobile = deviceType === MOBILE;

    return (
      <div className="position-relative">
        {!gameId ? <GameCardStub />
          : (
            <Fragment>
              <div className={classNames('game-card position-relative bg-dark-black icon-state-observer', {
                'game-card-small mx-0_25': isCardSmall,
                'games-card-mobile': isMobile,
              })}
              >
                <ImageLazyLoad
                  src="/assets/public/images/transparent-pixel.png"
                  className="game-card-image position-absolute"
                  classNameLoaded="game-card-image-loaded"
                  classNameError="game-card-image-error"
                  srcLazy={thumbPath}
                  type={LAZY_LOAD_TYPES.BACKGROUND}
                  srcFallback={DEFAULT_THUMB_PATH}
                  isUserLoggedIn={isUserLoggedIn}
                />

                <div
                  role="button"
                  tabIndex="0"
                  onClick={this.openGame}
                  onKeyPress={this.openGame}
                  onBlur={this.onOverlayBlur}
                  className={classNames('game-card-overlay d-flex justify-content-center align-items-center position-absolute', {
                    show: isCardOverlayShown,
                  })}
                >
                  <div className="d-flex flex-column align-items-center w-100 mt-n3">
                    <span
                      onClick={this.openGame}
                      onKeyPress={this.openGame}
                      role="button"
                      tabIndex="0"
                      className={classNames('play-real-btn position-relative', { 'mt-0_5 mt-md-0 mt-lg-1_25': !isCardSmall })}
                    >
                      <IconPlayCircle className={classNames('play-real-btn-icon', { 'icon-sm': isCardSmall })} />
                      {/* eslint-disable-next-line max-len */}
                      {/* <FormattedTag id="play" className="play-real-btn-text text-small letter-spacing-default position-absolute" /> */}
                    </span>

                    {!isCardSmall && !withFooter && (
                      <span className="game-card-overlay-title text-center text-truncate text-small text-white letter-spacing-default">
                        {title}
                      </span>
                    )}

                    {/* {!isCardSmall && hasFun && ( */}
                    {/* <Button */}
                    {/*   onClick={this.openDemoGame} */}
                    {/*   color="transparent" */}
                    {/*   className="d-flex play-demo-btn position-absolute px-0_5 py-0_25"> */}
                    {/*   <FormattedTag id="play-for-fun" className="text-white font-weight-bold" /> */}
                    {/* </Button> */}
                    {/* )} */}
                  </div>

                  {isUserLoggedIn && (
                    <span
                      className="game-card-favorite-icon d-flex justify-content-center align-items-center position-absolute"
                      onClick={this.toggleFavorite}
                      onKeyPress={this.toggleFavorite}
                      role="button"
                      tabIndex="0"
                    >
                      {isFavorite ? <IconStarActive /> : <IconStarOutline color={colors.white} />}
                    </span>
                  )}
                </div>

                {withJackpotAmount && !!amount && (
                  <CardLabel className="game-card-jackpot-amount position-absolute">
                    {formatAmountWithCurrency({ amount, currency, locale })}
                  </CardLabel>
                )}
              </div>

              {withFooter && (
                <div className="game-card-footer pt-1 pb-1_5">
                  <div className="font-weight-medium text-truncate">{title}</div>
                  <div className="text-small text-white-65 text-truncate">{producer}</div>
                </div>
              )}
            </Fragment>
          )}
      </div>
    );
  }
}

export const GameCard = withModalActions(
  withLocale(
    withUserAgent(
      withUser(
        GameCardUI, [
          USER_FIELDS.IS_USER_REGISTERED,
        ],
      ),
    ),
  ),
);
